const metroData = { 
    'Totals':['000s',15670,8036,2156,1716,4359,3677,5018,8381,5940,7696,7974,11640,2235,2258,858,3209,6424,8008,12833,12349,8410,13811,831,2427],
    '1':['000s',10298,5312,1117,862,2648,2664,3574,5702,4262,5203,5095,7897,1498,1514,586,2137,4325,5325,8481,8066,5713,9149,555,1740],
    '2':['000s',4424,2632,688,560,1440,1192,947,2347,1793,2445,1979,3238,801,765,285,1426,1949,2470,3688,3681,2357,3928,306,626],
    '3':['000s',4450,2545,575,427,1308,1237,1177,2359,1778,2315,2135,3290,663,677,242,1103,1888,2335,3669,3582,2351,3945,219,704],
    '4':['000s',6473,4101,1185,969,2550,1551,928,3291,2542,3371,3102,4526,1134,1148,408,1843,2866,3614,5390,5489,3303,5714,445,827],
    '1,2':['000s',11700,6116,1422,1119,3158,2958,3817,6373,4734,5922,5777,8856,1738,1743,680,2585,4893,6065,9630,9239,6400,10386,643,1898],
    '1,3':['000s',11797,6158,1390,1075,3150,3007,3908,6440,4776,5934,5863,8951,1714,1725,663,2501,4936,6095,9692,9281,6458,10455,624,1934],
    '1,4':['000s',12548,6699,1653,1312,3635,3064,3816,6733,4964,6276,6272,9353,1872,1889,713,2765,5260,6532,10326,9989,6777,11106,685,1981],
    '2,3':['000s',6985,4055,1059,835,2208,1847,1631,3691,2759,3676,3309,5107,1159,1137,416,1936,3019,3782,5792,5719,3679,6177,424,1026],
    '2,4':['000s',7912,4840,1370,1119,2875,1965,1410,4083,3129,4065,3847,5632,1358,1334,489,2135,3454,4353,6588,6614,4087,6997,520,1074],
    '3,4':['000s',8681,5245,1440,1154,3068,2177,1680,4479,3398,4458,4223,6197,1420,1438,516,2254,3780,4723,7208,7193,4499,7655,527,1217],
    '1,2,3':['000s',12623,6616,1606,1262,3463,3153,4026,6827,5023,6341,6283,9492,1868,1870,719,2764,5266,6538,10382,9985,6857,11175,683,2021],
    '1,2,4':['000s',13024,6927,1748,1394,3751,3176,3955,6992,5134,6490,6534,9704,1950,1949,742,2860,5445,6767,10729,10364,7016,11535,714,2046],
    '1,3,4':['000s',13309,7089,1782,1414,3842,3248,4038,7134,5217,6638,6671,9901,1969,1980,746,2903,5564,6918,10954,10583,7175,11768,715,2089],
    '2,3,4':['000s',9510,5660,1584,1277,3271,2389,1936,4934,3719,4832,4678,6824,1559,1543,565,2420,4109,5145,7894,7846,4945,8394,581,1339],
    '1,2,3,4':['000s',13592,7215,1860,1482,3910,3305,4111,7283,5309,6751,6841,10103,2017,2016,764,2954,5668,7050,11196,10811,7310,12022,736,2123],
    }
    const metroPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',65.72,66.1,51.81,50.23,60.75,72.45,71.22,68.03,71.75,67.61,63.9,67.84,67.02,67.05,68.3,66.59,67.33,66.5,66.09,65.32,67.93,66.24,66.79,71.69],
    '2':['Vert%',28.23,32.75,31.91,32.63,33.04,32.42,18.87,28,30.19,31.77,24.82,27.82,35.84,33.88,33.22,44.44,30.34,30.84,28.74,29.81,28.03,28.44,36.82,25.79],
    '3':['Vert%',28.4,31.67,26.67,24.88,30.01,33.64,23.46,28.15,29.93,30.08,26.77,28.26,29.66,29.98,28.21,34.37,29.39,29.16,28.59,29.01,27.95,28.56,26.35,29.01],
    '4':['Vert%',41.31,51.03,54.96,56.47,58.5,42.18,18.49,39.27,42.79,43.8,38.9,38.88,50.74,50.84,47.55,57.43,44.61,45.13,42,44.45,39.27,41.37,53.55,34.07],
    '1,2':['Vert%',74.66,76.11,65.96,65.21,72.45,80.45,76.07,76.04,79.7,76.95,72.45,76.08,77.76,77.19,79.25,80.55,76.17,75.74,75.04,74.82,76.1,75.2,77.38,78.2],
    '1,3':['Vert%',75.28,76.63,64.47,62.65,72.26,81.78,77.88,76.84,80.4,77.1,73.53,76.9,76.69,76.4,77.27,77.94,76.84,76.11,75.52,75.16,76.79,75.7,75.09,79.69],
    '1,4':['Vert%',80.08,83.36,76.67,76.46,83.39,83.33,76.05,80.34,83.57,81.55,78.66,80.35,83.76,83.66,83.1,86.16,81.88,81.57,80.46,80.89,80.58,80.41,82.43,81.62],
    '2,3':['Vert%',44.58,50.46,49.12,48.66,50.65,50.23,32.5,44.04,46.45,47.77,41.5,43.87,51.86,50.35,48.48,60.33,47,47.23,45.13,46.31,43.75,44.73,51.02,42.27],
    '2,4':['Vert%',50.49,60.23,63.54,65.21,65.96,53.44,28.1,48.72,52.68,52.82,48.24,48.38,60.76,59.08,56.99,66.53,53.77,54.36,51.34,53.56,48.6,50.66,62.58,44.25],
    '3,4':['Vert%',55.4,65.27,66.79,67.25,70.38,59.21,33.48,53.44,57.21,57.93,52.96,53.24,63.53,63.68,60.14,70.24,58.84,58.98,56.17,58.25,53.5,55.43,63.42,50.14],
    '1,2,3':['Vert%',80.56,82.33,74.49,73.54,79.44,85.75,80.23,81.46,84.56,82.39,78.79,81.55,83.58,82.82,83.8,86.13,81.97,81.64,80.9,80.86,81.53,80.91,82.19,83.27],
    '1,2,4':['Vert%',83.11,86.2,81.08,81.24,86.05,86.37,78.82,83.43,86.43,84.33,81.94,83.37,87.25,86.32,86.48,89.12,84.76,84.5,83.6,83.93,83.42,83.52,85.92,84.3],
    '1,3,4':['Vert%',84.93,88.22,82.65,82.4,88.14,88.33,80.47,85.12,87.83,86.25,83.66,85.06,88.1,87.69,86.95,90.46,86.61,86.39,85.36,85.7,85.32,85.21,86.04,86.07],
    '2,3,4':['Vert%',60.69,70.43,73.47,74.42,75.04,64.97,38.58,58.87,62.61,62.79,58.67,58.63,69.75,68.33,65.85,75.41,63.96,64.25,61.51,63.54,58.8,60.78,69.92,55.17],
    '1,2,3,4':['Vert%',86.74,89.78,86.27,86.36,89.7,89.88,81.93,86.9,89.38,87.72,85.79,86.8,90.25,89.28,89.04,92.05,88.23,88.04,87.24,87.55,86.92,87.05,88.57,87.47],
    }
    const nationalData = { 
    'Totals':['000s',22119,10939,2958,2338,5838,5101,7568,11906,8199,10886,11233,16704,2952,3124,1168,4135,9022,11159,18207,17050,11834,19473,1120,3649],
    '1':['000s',14360,7193,1559,1194,3556,3638,5195,7938,5811,7205,7155,11178,1976,2055,778,2746,6010,7353,11904,11048,7902,12753,737,2545],
    '2':['000s',5848,3405,889,713,1831,1574,1340,3076,2353,3245,2603,4351,989,987,355,1688,2558,3198,4859,4759,3112,5194,389,874],
    '3':['000s',6041,3324,792,588,1665,1659,1710,3172,2346,3189,2852,4525,870,925,330,1307,2565,3115,5003,4732,3213,5355,321,997],
    '4':['000s',8640,5363,1591,1303,3312,2050,1340,4398,3405,4438,4202,6145,1443,1476,531,2318,3772,4732,7242,7177,4392,7641,575,1181],
    '1,2':['000s',16231,8250,1954,1523,4192,4058,5548,8836,6434,8181,8050,12479,2271,2356,901,3273,6785,8343,13441,12582,8834,14404,847,2770],
    '1,3':['000s',16407,8290,1930,1480,4177,4114,5701,8936,6475,8233,8174,12631,2265,2356,891,3178,6855,8395,13559,12663,8931,14527,850,2818],
    '1,4':['000s',17365,9001,2269,1796,4815,4186,5564,9329,6769,8635,8731,13163,2450,2549,954,3537,7264,8937,14388,13560,9315,15381,911,2887],
    '2,3':['000s',9417,5322,1406,1095,2828,2494,2361,4952,3682,5004,4413,6997,1474,1521,546,2323,4054,4998,7805,7516,4987,8320,570,1478],
    '2,4':['000s',10686,6393,1847,1503,3750,2643,2039,5498,4221,5463,5223,7730,1736,1750,633,2675,4615,5792,8937,8762,5518,9464,674,1540],
    '3,4':['000s',11726,6892,1956,1567,3993,2899,2454,6045,4540,6003,5723,8508,1823,1902,689,2817,5049,6249,9788,9505,6086,10349,703,1740],
    '1,2,3':['000s',17510,8905,2203,1714,4574,4331,5876,9465,6830,8785,8725,13372,2446,2542,963,3488,7303,8983,14474,13579,9476,15488,921,2953],
    '1,2,4':['000s',18065,9341,2404,1908,4977,4363,5768,9708,7016,8962,9103,13676,2551,2644,991,3656,7539,9299,14979,14107,9680,16005,946,2994],
    '1,3,4':['000s',18436,9516,2451,1933,5075,4440,5910,9892,7099,9158,9278,13939,2586,2687,1005,3701,7694,9475,15263,14377,9884,16299,961,3049],
    '2,3,4':['000s',12939,7512,2151,1724,4277,3235,2822,6705,5028,6571,6368,9433,2008,2066,753,3022,5547,6886,10787,10452,6745,11427,768,1939],
    '1,2,3,4':['000s',18862,9720,2555,2023,5177,4543,6017,10122,7254,9342,9520,14244,2648,2746,1029,3765,7854,9686,15623,14714,10100,16679,985,3114],
    }
    const nationalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',64.92,65.76,52.7,51.07,60.91,71.32,68.64,66.67,70.87,66.19,63.7,66.92,66.94,65.78,66.61,66.41,66.61,65.89,65.38,64.8,66.77,65.49,65.8,69.75],
    '2':['Vert%',26.44,31.13,30.05,30.5,31.36,30.86,17.71,25.84,28.7,29.81,23.17,26.05,33.5,31.59,30.39,40.82,28.35,28.66,26.69,27.91,26.3,26.67,34.73,23.95],
    '3':['Vert%',27.31,30.39,26.77,25.15,28.52,32.52,22.6,26.64,28.61,29.29,25.39,27.09,29.47,29.61,28.25,31.61,28.43,27.91,27.48,27.75,27.15,27.5,28.66,27.32],
    '4':['Vert%',39.06,49.03,53.79,55.73,56.73,40.19,17.71,36.94,41.53,40.77,37.41,36.79,48.88,47.25,45.46,56.06,41.81,42.41,39.78,42.09,37.11,39.24,51.34,32.37],
    '1,2':['Vert%',73.38,75.42,66.06,65.14,71.81,79.55,73.31,74.21,78.47,75.15,71.66,74.71,76.93,75.42,77.14,79.15,75.21,74.76,73.82,73.79,74.65,73.97,75.63,75.91],
    '1,3':['Vert%',74.18,75.78,65.25,63.3,71.55,80.65,75.33,75.05,78.97,75.63,72.77,75.62,76.73,75.42,76.28,76.86,75.98,75.23,74.47,74.27,75.47,74.6,75.89,77.23],
    '1,4':['Vert%',78.51,82.28,76.71,76.82,82.48,82.06,73.52,78.36,82.56,79.32,77.73,78.8,82.99,81.59,81.68,85.54,80.51,80.09,79.02,79.53,78.71,78.99,81.34,79.12],
    '2,3':['Vert%',42.57,48.65,47.53,46.83,48.44,48.89,31.2,41.59,44.91,45.97,39.29,41.89,49.93,48.69,46.75,56.18,44.93,44.79,42.87,44.08,42.14,42.73,50.89,40.5],
    '2,4':['Vert%',48.31,58.44,62.44,64.29,64.23,51.81,26.94,46.18,51.48,50.18,46.5,46.28,58.81,56.02,54.2,64.69,51.15,51.9,49.09,51.39,46.63,48.6,60.18,42.2],
    '3,4':['Vert%',53.01,63,66.13,67.02,68.4,56.83,32.43,50.77,55.37,55.14,50.95,50.93,61.75,60.88,58.99,68.13,55.96,56,53.76,55.75,51.43,53.15,62.77,47.68],
    '1,2,3':['Vert%',79.16,81.41,74.48,73.31,78.35,84.9,77.64,79.5,83.3,80.7,77.67,80.05,82.86,81.37,82.45,84.35,80.95,80.5,79.5,79.64,80.07,79.54,82.23,80.93],
    '1,2,4':['Vert%',81.67,85.39,81.27,81.61,85.25,85.53,76.22,81.54,85.57,82.33,81.04,81.87,86.42,84.64,84.85,88.42,83.56,83.33,82.27,82.74,81.8,82.19,84.46,82.05],
    '1,3,4':['Vert%',83.35,86.99,82.86,82.68,86.93,87.04,78.09,83.08,86.58,84.13,82.6,83.45,87.6,86.01,86.04,89.5,85.28,84.91,83.83,84.32,83.52,83.7,85.8,83.56],
    '2,3,4':['Vert%',58.5,68.67,72.72,73.74,73.26,63.42,37.29,56.32,61.32,60.36,56.69,56.47,68.02,66.13,64.47,73.08,61.48,61.71,59.25,61.3,57,58.68,68.57,53.14],
    '1,2,3,4':['Vert%',85.28,88.86,86.38,86.53,88.68,89.06,79.51,85.02,88.47,85.82,84.75,85.27,89.7,87.9,88.1,91.05,87.05,86.8,85.81,86.3,85.35,85.65,87.95,85.34],
    }
    const regionalData = { 
    'Totals':['000s',6449,2903,802,622,1479,1424,2550,3525,2259,3190,3259,5064,717,866,311,926,2598,3151,5374,4702,3424,5662,288,1222],
    '1':['000s',4061,1881,442,332,907,974,1622,2236,1549,2002,2060,3281,478,541,192,609,1685,2027,3423,2982,2189,3604,182,805],
    '2':['000s',1424,773,201,153,392,381,394,730,560,799,624,1114,188,223,71,262,609,728,1171,1078,755,1266,83,248],
    '3':['000s',1591,779,218,160,357,422,534,813,568,874,717,1236,207,249,87,204,676,780,1333,1150,862,1410,102,293],
    '4':['000s',2167,1261,405,334,762,499,412,1107,863,1067,1101,1620,309,328,123,476,907,1118,1852,1687,1089,1928,131,354],
    '1,2':['000s',4532,2134,532,405,1034,1100,1731,2463,1701,2259,2273,3623,534,614,220,688,1892,2278,3811,3343,2434,4018,204,872],
    '1,3':['000s',4610,2133,541,405,1027,1106,1793,2495,1699,2299,2311,3680,550,631,228,677,1919,2299,3866,3382,2473,4073,226,884],
    '1,4':['000s',4817,2302,616,484,1180,1122,1748,2596,1805,2358,2459,3810,578,660,241,772,2004,2405,4063,3571,2539,4275,226,905],
    '2,3':['000s',2432,1266,347,260,619,647,730,1261,923,1328,1104,1890,315,383,131,387,1035,1216,2013,1796,1308,2143,146,452],
    '2,4':['000s',2775,1554,477,384,875,678,629,1415,1093,1399,1376,2098,379,416,145,539,1161,1439,2349,2148,1431,2467,154,466],
    '3,4':['000s',3045,1647,516,413,925,722,774,1566,1142,1546,1500,2312,403,464,174,563,1269,1526,2581,2311,1587,2695,175,522],
    '1,2,3':['000s',4887,2289,597,452,1111,1178,1850,2638,1807,2445,2442,3881,578,671,244,725,2037,2446,4092,3595,2620,4314,239,932],
    '1,2,4':['000s',5041,2414,656,514,1227,1187,1812,2716,1883,2472,2569,3972,601,695,249,795,2094,2532,4250,3743,2663,4471,232,948],
    '1,3,4':['000s',5127,2427,668,519,1234,1193,1872,2758,1882,2520,2607,4038,617,708,259,797,2131,2557,4309,3794,2709,4531,246,960],
    '2,3,4':['000s',3429,1852,567,448,1005,847,886,1771,1309,1739,1690,2609,449,523,187,602,1438,1741,2892,2606,1800,3033,187,600],
    '1,2,3,4':['000s',5270,2505,695,540,1267,1238,1906,2839,1945,2591,2678,4141,631,730,265,810,2187,2636,4428,3902,2790,4657,250,992],
    }
    const regionalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',62.97,64.8,55.11,53.38,61.33,68.4,63.61,63.43,68.57,62.76,63.21,64.79,66.67,62.47,61.74,65.77,64.86,64.33,63.7,63.42,63.93,63.65,63.19,65.88],
    '2':['Vert%',22.08,26.63,25.06,24.6,26.5,26.76,15.45,20.71,24.79,25.05,19.15,22,26.22,25.75,22.83,28.29,23.44,23.1,21.79,22.93,22.05,22.36,28.82,20.29],
    '3':['Vert%',24.67,26.83,27.18,25.72,24.14,29.63,20.94,23.06,25.14,27.4,22,24.41,28.87,28.75,27.97,22.03,26.02,24.75,24.8,24.46,25.18,24.9,35.42,23.98],
    '4':['Vert%',33.6,43.44,50.5,53.7,51.52,35.04,16.16,31.4,38.2,33.45,33.78,31.99,43.1,37.88,39.55,51.4,34.91,35.48,34.46,35.88,31.8,34.05,45.49,28.97],
    '1,2':['Vert%',70.27,73.51,66.33,65.11,69.91,77.25,67.88,69.87,75.3,70.82,69.75,71.54,74.48,70.9,70.74,74.3,72.83,72.29,70.92,71.1,71.09,70.96,70.83,71.36],
    '1,3':['Vert%',71.48,73.48,67.46,65.11,69.44,77.67,70.31,70.78,75.21,72.07,70.91,72.67,76.71,72.86,73.31,73.11,73.86,72.96,71.94,71.93,72.23,71.94,78.47,72.34],
    '1,4':['Vert%',74.69,79.3,76.81,77.81,79.78,78.79,68.55,73.65,79.9,73.92,75.45,75.24,80.61,76.21,77.49,83.37,77.14,76.32,75.6,75.95,74.15,75.5,78.47,74.06],
    '2,3':['Vert%',37.71,43.61,43.27,41.8,41.85,45.44,28.63,35.77,40.86,41.63,33.88,37.32,43.93,44.23,42.12,41.79,39.84,38.59,37.46,38.2,38.2,37.85,50.69,36.99],
    '2,4':['Vert%',43.03,53.53,59.48,61.74,59.16,47.61,24.67,40.14,48.38,43.86,42.22,41.43,52.86,48.04,46.62,58.21,44.69,45.67,43.71,45.68,41.79,43.57,53.47,38.13],
    '3,4':['Vert%',47.22,56.73,64.34,66.4,62.54,50.7,30.35,44.43,50.55,48.46,46.03,45.66,56.21,53.58,55.95,60.8,48.85,48.43,48.03,49.15,46.35,47.6,60.76,42.72],
    '1,2,3':['Vert%',75.78,78.85,74.44,72.67,75.12,82.72,72.55,74.84,79.99,76.65,74.93,76.64,80.61,77.48,78.46,78.29,78.41,77.63,76.14,76.46,76.52,76.19,82.99,76.27],
    '1,2,4':['Vert%',78.17,83.16,81.8,82.64,82.96,83.36,71.06,77.05,83.36,77.49,78.83,78.44,83.82,80.25,80.06,85.85,80.6,80.36,79.08,79.6,77.77,78.97,80.56,77.58],
    '1,3,4':['Vert%',79.5,83.6,83.29,83.44,83.43,83.78,73.41,78.24,83.31,79,79.99,79.74,86.05,81.76,83.28,86.07,82.02,81.15,80.18,80.69,79.12,80.02,85.42,78.56],
    '2,3,4':['Vert%',53.17,63.8,70.7,72.03,67.95,59.48,34.75,50.24,57.95,54.51,51.86,51.52,62.62,60.39,60.13,65.01,55.35,55.25,53.81,55.42,52.57,53.57,64.93,49.1],
    '1,2,3,4':['Vert%',81.72,86.29,86.66,86.82,85.67,86.94,74.75,80.54,86.1,81.22,82.17,81.77,88.01,84.3,85.21,87.47,84.18,83.66,82.4,82.99,81.48,82.25,86.81,81.18],
    }


const graph_source = 'Nielsen CMV, National Survey 10 2024'

const exportedObject = {
    metroData,
    metroPercentage,
    nationalData,
    nationalPercentage,
    regionalData,
    regionalPercentage,
    graph_source

}

export default exportedObject


    